const url = window.location.origin;

export const getEnv = () => {
  switch (true) {
    case url.includes('dsmotd.toyota.com'):
      return 'prod';
    case url.includes('one.vcg.cubeuat.toyota.com'):
      return 'uat';
    case url.includes('one.vcg.cubeqa.toyota.com'):
      return 'qa';
    case url.includes('one.vcg.cubedev.toyota.com'):
    case url.includes('localhost'):
    default:
      return 'dev';
  }
};

export const getClientIdByEnv = () => {
  switch (true) {
    case url.includes('one.vcg.cubeuat.toyota.com'):
      return '23d9ed3c-dcf2-4698-b53d-632805f3c652';
    case url.includes('one.vcg.cubeqa.toyota.com'):
      return '04067b84-97f9-4359-8571-8d2388f63af7';
    case url.includes('one.vcg.cubedev.toyota.com'):
    case url.includes('localhost'):
    default:
      return 'ab36062b-52f8-4a39-a17b-c83c5f003b3d';
  }
};

export const getAppSyncUrlByEnv = () => {
  switch (true) {
    case url.includes('one.vcg.cubeuat.toyota.com'):
      return 'https://ir2vlpv3obbebft7v63aovdt5i.appsync-api.us-west-2.amazonaws.com';
    case url.includes('one.vcg.cubeqa.toyota.com'):
      return 'https://f7zmdfomr5gx3mx6n7wkujxqzi.appsync-api.us-west-2.amazonaws.com';
    case url.includes('one.vcg.cubedev.toyota.com'):
    case url.includes('localhost'):
    default:
      return 'https://cnce4qsekbbwvndxhol3bt22o4.appsync-api.us-west-2.amazonaws.com';
  }
};

export const getAPIKeyByEnv = () => {
  switch (true) {
    case url.includes('one.vcg.cubeuat.toyota.com'):
      return 'da2-6anbfxy7azf43cfqnl3zixl3xy';
    case url.includes('one.vcg.cubeqa.toyota.com'):
      return 'da2-v63qgfieh5c5nea5rcjokwodlm';
    case url.includes('one.vcg.cubedev.toyota.com'):
    case url.includes('localhost'):
    default:
      return 'da2-wb4zwcd6jzglthwuryaewlkjpq';
  }
};

export const getBaseURL = () =>
  ({
    dev: 'one.vcg.cubedev.toyota.com',
    qa: 'one.vcg.cubeqa.toyota.com',
    uat: 'one.vcg.cubeuat.toyota.com',
    prod: 'dsmotd.toyota.com',
  }[getEnv()]);

export const appSyncURL = 'https://cnce4qsekbbwvndxhol3bt22o4.appsync-api.us-west-2.amazonaws.com';
export const apiKey = 'da2-wb4zwcd6jzglthwuryaewlkjpq';

const vspecGuest = 'https://guest.dealer.qa.toyota.com';
const vSpecHash = 'https://api.rti.qa.toyota.com';
const vSpecXKey = '899a5fe823ed56c5860c5e5ab0eb170c';

export const getVspecParams = () =>
  ({
    dev: {
      vSpecGuestUrl: vspecGuest,
      vSpecHashUrl: vSpecHash,
      vSpecXApiKey: vSpecXKey,
    },
    qa: {
      vSpecGuestUrl: vspecGuest,
      vSpecHashUrl: vSpecHash,
      vSpecXApiKey: vSpecXKey,
    },
    uat: {
      vSpecGuestUrl: vspecGuest,
      vSpecHashUrl: vSpecHash,
      vSpecXApiKey: vSpecXKey,
    },
    prod: {
      vSpecGuestUrl: 'https://guest.dealer.toyota.com',
      vSpecHashUrl: 'https://api.rti.toyota.com',
      vSpecXApiKey: '89ad76674ea851fdb45f243ae30ec351',
    },
  }[getEnv()]);
